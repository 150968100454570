(function () {
	'use strict';
	angular.module('eventvods')
		.controller('PageController', ['SessionManager', '$rootScope', '$timeout', '$cookies', '$window', '$location', '$http', 'API_BASE_URL', 
			function (SessionManager, $rootScope, $timeout, $cookies, $window, $location, $http, API) {
				var vm = this;
				vm.session = null;
				// vm.cookiesAccepted = ($cookies.get('cookieDisclaimer') === "true");
				vm.showValorantPopup = ($cookies.get('valorantPopup') !== "true") && !$location.path().match('valorant') && $window.moment().isBefore($window.moment('2020-06-30T00:00:00.000Z'))
				// vm.acceptCookies = function () {
				// 	$cookies.put('cookieDisclaimer', true);
				// };
				vm.gotoValorant = function(){
					$cookies.put('valorantPopup', true);
					return $location.path('/featured/valorant').search({});
				}
				$rootScope.$on('sessionUpdate', function () {
					vm.session = SessionManager.get();
					vm.logged_in = !(vm.session == false || vm.session == null);
					vm.inlineYoutube = inlineYoutube();
					vm.inlineTwitch = inlineTwitch();
					vm.inlineFacebook = inlineFacebook();
				});
				vm.home = function () {
					var loc = $location.path();
					if (loc.match('/featured/'))
						return $rootScope.$emit('featuredReset');
					if (loc.match('/events/') && loc == '/events/')
						return $location.path('/').search({});
					else if (loc.match('/events/'))
						return $rootScope.$emit('eventsReset');
					if ($rootScope.oldGame != null && $rootScope.oldGame != 'static')
						return $location.path('/featured/' + $rootScope.oldGame).search({});
					else
						return $location.path('/').search({});
				};
				vm.logout = function () {
					SessionManager.logout();
				};
				vm.triggerResizeHandler = function () {
					$timeout(function () {
						$(window).trigger('resize');
					}, 50);
				};
				vm.closeModal = function () {
					$('[data-toggle="tooltip"]').tooltip('dispose');
					$('.modal.show').modal('hide');
					$('[data-toggle="tooltip"]').tooltip();
				}
				vm.collection = function (number) {
					return Array(number).join(".").split(".")
				};
				vm.transformMedia = function (media) {
					var res;
					switch (media.toLowerCase()) {
						case "twitter":
						case "facebook":
						case "youtube":
						case "twitch":
						case "flickr":
						case "snapchat":
						case "reddit":
						case "wiki":
						case "instagram":
						case "linkedin":
							res = media.toLowerCase() + ".svg";
							break;
						default:
							res = "website.svg";
					}
					return res;
				}
				vm.longest = function (array, prop) {
					if (typeof array == "undefined") return "";
					var l = 0;
					for (var i = 0; i < array.length; i++) {
						if (array[l][prop].length < array[i][prop].length) l = i;
					}
					return array[l][prop];
				}
				var followDiffs = {};
				vm.following = function (id) {
					if (vm.session == false || vm.session == null || vm.session.following == null) return false;
					else if (vm.session.following.indexOf(id) > -1) return true;
					else return false;
				}
				vm.login = function(){
					$location.path('/login').search({});
				}
				vm.toggleFollow = function (id, $event) {
					$event.preventDefault();
					$event.stopPropagation();
					if (!vm.logged_in) return vm.login();
					var index = vm.session.following == null ? -1 : vm.session.following.indexOf(id);
					if (index > -1) {
						vm.session.following.splice(index, 1);
						if (id in followDiffs) followDiffs[id] = followDiffs[id] - 1;
						else followDiffs[id] = -1;
					} else {
						vm.session.following.push(id);
						if (id in followDiffs) followDiffs[id] = followDiffs[id] + 1;
						else followDiffs[id] = +1;
					}
					SessionManager.following(vm.session.following);
				}
				vm.followers = function (event) {
					if (event == null) return 0;
					var diff = followDiffs[event._id];
					if (typeof diff === "undefined") diff = 0;
					return event.followers + diff;
				}
				vm.watched = function (match, gameIndex) {
					if (vm.session == false || vm.session == null || vm.session.watched.length == 0 || match.data[gameIndex] == void 0) return false;
					if(match.data[gameIndex].placeholder && gameIndex > 0) return vm.watched(match, gameIndex-1);
					var match = vm.session.watched.find(item => item.match == match._id);
					return !(!match || !match.games[gameIndex]);
				}
				vm.countWatched = function(match){
					var n = 0;
					for(var i = 0; i < match.bestOf; i++)
						if(vm.watched(match, i)) n++;
					return n;
				}
				vm.complete = function (match) {
					return (vm.countWatched(match) >= match.bestOf);
				}
				vm.setWatched = function (matchID, gameIndex) {
					vm.session.watched = vm.session.watched || [];
					var match = vm.session.watched.findIndex(item => item.match == matchID);
					if(match > -1) vm.session.watched[match].games[gameIndex] = true;
					else vm.session.watched.push({
						match: matchID,
						games: new Array(gameIndex+1).fill(false, 0, gameIndex).fill(true, gameIndex)
					});
					SessionManager.watched(vm.session.watched);
				}
				vm.allWatched = function (match) {
					if (!vm.logged_in) return vm.login();
					for (var i = 0; i < match.bestOf; i++)
						vm.setWatched(match._id, i);
				}
				$rootScope.$on('setWatched', function (e, id, index) {
					if(vm.logged_in) vm.setWatched(id, index);
				})
				vm.rateGame = function (match, index, rating) {
					if (!vm.logged_in) return vm.login();
					if (rating < 1 || rating > 5) return;
					if (vm.session == false || vm.session == null) return false;
					else $http.post(API + '/ratings', {
						match: match,
						index: index,
						rating: rating
					});
				}
				var inlineYoutube = function () {
					if (!vm.logged_in) return true;
					else return vm.session.settings.inline.youtube;
				}
				var inlineTwitch = function () {
					if (!vm.logged_in) return true;
					else return vm.session.settings.inline.twitch;
				}
				var inlineFacebook = function () {
					if (!vm.logged_in) return true;
					else return vm.session.settings.inline.facebook;
				}
				vm.contactEmail = atob("aW5mb0BldmVudHZvZHMuY29t");
				$rootScope.$on('reloadFeatured', function () {
					followDiffs = {};
				});
				vm.escapePattern = function (string) {
					return (string || "").replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
				}
				vm.debounceAds = function(){
					if(vm.adDebounce) return;
					vm.adDebounce = true;
					$timeout(function(){
						vm.createAds();
						vm.adDebounce = false;
					}, 500)
				}
				vm.createAds = function(){
						$window.top.__vm_add = $window.top.__vm_add || [];
						var elms = document.querySelectorAll('.vm-placement');
						for(var i = 0; i < elms.length; i++){
							$window.top.__vm_add.push(elms[i]);
						}
				}
			}
		])
		.controller('StaticController', ['$rootScope', function ($rootScope) {
			if ($rootScope.game == 'static') return;
			$rootScope.oldGame = $rootScope.game;
			$rootScope.game = 'static';
		}]);
}());
